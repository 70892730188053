import Clickable from '/components/Clickable';
import Image from '/components/Image';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';

const FlyerBanner = ({ flyer }) => {
	return (
		<Clickable
			className="block h-full first:mx-4 last:mr-4 relative w-full"
			heapEventData={{
				'click-anchor-href': flyer.anchorHref,
				'click-title': flyer.title,
			}}
			heapEventName="flyers-banner-image-click"
			linkToInternal={flyer.anchorHref}>
			<div className="md:hidden">
				<Image
					alt={flyer.imageAlt}
					layout="fill"
					objectFit="cover"
					objectPosition="center"
					src={flyer.imageSrcMobile}
				/>
			</div>

			<div className="hidden md:block">
				<Image
					alt={flyer.imageAlt}
					layout="fill"
					objectFit="cover"
					objectPosition="center"
					src={flyer.imageSrcDesktop}
				/>
			</div>

			<Typography
				className={classNames(
					'absolute bottom-0 left-0 px-4 pb-4 w-full',
					flyer.classText,
					'md:hidden',
				)}
				variant="subtitle-lg">
				{flyer.title}
			</Typography>

			<Typography
				className={classNames(
					'absolute bottom-0 hidden left-0 px-4 pb-4 max-w-[240px]',
					flyer.classText,
					'md:block',
				)}
				variant="subtitle-lg">
				{flyer.title}
			</Typography>
		</Clickable>
	);
};

FlyerBanner.propTypes = {
	flyer: PropTypes.object,
};

export default FlyerBanner;
