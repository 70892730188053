import MeasurementTable from '/components/size-guides/MeasurementTable';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import { getSizeGuide } from '/services/size-guides';

import { useEffect, useState } from 'react';

const SizeGuide = ({ config }) => {
	const [sizeGuide, setSizeGuide] = useState();

	useEffect(() => {
		const fetchSizeGuide = async () => {
			const sizeGuideResponse = await getSizeGuide(config.styleNumber);
			if (sizeGuideResponse && !sizeGuideResponse.hasError) {
				setSizeGuide(sizeGuideResponse);
			}
		};

		if (config.styleNumber) {
			fetchSizeGuide();
		}
	}, [config.styleNumber]);

	if (!sizeGuide) {
		return null;
	}

	return (
		<div className="flex items-center justify-center text-center flex-col container max-w-3xl mb-2 md:mb-4">
			{config.heading && (
				<Typography className="mb-2 text-center md:mb-4" component="h2" variant="subhead-lg">
					{config.heading}
				</Typography>
			)}
			{sizeGuide && sizeGuide.note && (
				<Typography component="p" variant="body">
					{sizeGuide.note}
				</Typography>
			)}
			{sizeGuide.measurementTables.map((measurementTable, i) => {
				return (
					<div className="w-full" key={i}>
						<MeasurementTable tableDetails={measurementTable} />
					</div>
				);
			})}
		</div>
	);
};

SizeGuide.propTypes = {
	config: PropTypes.object,
};

export default SizeGuide;
