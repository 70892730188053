import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';

const Youtube = ({ config }) => {
	if (!config || !config.youtubeSrc) {
		return null;
	}

	return (
		<div className="container max-w-7xl mb-2 px-2 md:mb-4">
			{config.heading && (
				<Typography className="mb-2 text-center md:mb-4" component="h2" variant="subhead-lg">
					{config.heading}
				</Typography>
			)}
			<div className="flex items-center justify-center">
				<iframe
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
					className={classNames(
						'',
						config.isPortrait
							? 'min-h-[500px] md:min-h-[700px] mt-8 w-full'
							: 'h-auto md:w-[560px] md:h-[315px] mt-8 w-full',
					)}
					frameBorder="0"
					src={config.youtubeSrc}
					title="Tommy Brown x Shinesty"
				/>
			</div>
		</div>
	);
};

Youtube.propTypes = {
	config: PropTypes.object,
};

export default Youtube;
