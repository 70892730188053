import Image from '/components/Image';
import Markdown from '/components/Markdown';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';

import classNames from '/utils/class-names';

const TextWithImage = ({ config }) => {
	return (
		<div
			className={classNames(
				'flex flex-col justify-center container max-w-7xl md:my-8 md:gap-8 md:px-0 my-4 px-4',
				config.componentLayout === 'image-right' ? 'md:flex-row-reverse' : 'md:flex-row',
			)}>
			<div className="relative h-[250px] md:w-1/2 md:h-[500px] w-full">
				<Image
					src={config.imageSrcDesktop}
					alt={config.altText}
					layout="fill"
					objectFit="cover"
					objectPosition="center"
				/>
			</div>
			<div className="md:self-center md:w-1/2">
				{config.heading && (
					<Typography className="my-4 text-center" component="h1" variant="subhead-lg">
						{config.heading}
					</Typography>
				)}
				{config.content && <Markdown content={config.content} />}
			</div>
		</div>
	);
};

TextWithImage.propTypes = {
	config: PropTypes.object,
};

export default TextWithImage;
