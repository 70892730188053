import HeroV1 from '/components/lp/sections/hero/HeroV1';
import HeroV2 from '/components/lp/sections/hero/HeroV2';

import PropTypes from 'prop-types';

const Hero = ({ config }) => {
	if (config.heroVersion === 'v2') {
		return <HeroV2 config={config} />;
	}

	return <HeroV1 config={config} />;
};

Hero.propTypes = {
	config: PropTypes.object,
};

export default Hero;
