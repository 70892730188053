import Markdown from '/components/Markdown';
import PropTypes from 'prop-types';
import classNames from '/utils/class-names';

const MarkdownSection = ({ config }) => {
	const alignments = {
		center: 'text-center',
		left: 'text-left',
		right: 'text-right',
	};

	const widths = {
		xs: 'max-w-md',
		s: 'max-w-xl',
		m: 'max-w-3xl',
		l: 'max-w-5xl',
		xl: 'max-w-7xl',
	};

	return (
		<div
			className={classNames(
				'container mb-4 px-4',
				config.alignment && alignments[config.alignment]
					? alignments[config.alignment]
					: 'text-left',
				config.width && widths[config.width] ? widths[config.width] : 'max-w-7xl',
			)}>
			<Markdown content={config.markdown} />
		</div>
	);
};

MarkdownSection.propTypes = {
	config: PropTypes.object,
};

export default MarkdownSection;
