import PropTypes from 'prop-types';
import Typography from '/components/Typography';

const GoogleForm = ({ config }) => {
	return (
		<div className="flex justify-center container max-w-7xl mb-4">
			{config.heading && (
				<Typography className="text-center mb-4" component="h1" variant="subhead-lg">
					{config.heading}
				</Typography>
			)}
			<iframe
				src={config.embedSrc}
				width={config.width}
				height={config.height}
				frameBorder="0"
				marginHeight="0"
				marginWidth="0">
				Loading…
			</iframe>
		</div>
	);
};

GoogleForm.propTypes = {
	config: PropTypes.object,
};

export default GoogleForm;
