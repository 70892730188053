import Carousel from '/components/Carousel';
import ProductCard from '/components/collections/ProductCard';
import { PropTypes } from 'prop-types';

const ProductCarousel = ({ products }) => {
	if (!products || products.length === 0) {
		return null;
	}

	const maxSlidesToShow = products.length < 6 ? products.length : 6;

	return (
		<Carousel
			className="black mb-12"
			dots={true}
			slidesToShow={maxSlidesToShow}
			responsive={[
				{
					breakpoint: 1536,
					settings: {
						slidesToShow: maxSlidesToShow,
					},
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 4,
					},
				},
				{
					breakpoint: 640,
					settings: {
						slidesToShow: 2,
					},
				},
			]}
			slidesToScroll={2}>
			{products.map((product, i) => {
				return (
					<div className="lg:px-3 h-full" key={i}>
						<ProductCard
							key={i}
							product={product}
							variant="mini"
							rank={i}
							showQuickShop
							source="shopify"
						/>
					</div>
				);
			})}
		</Carousel>
	);
};

ProductCarousel.propTypes = {
	products: PropTypes.array,
};

export default ProductCarousel;
