import Clickable from '/components/Clickable';
import Image from '/components/Image';
import PropTypes from 'prop-types';

const ImageSection = ({ config }) => {
	if (!config.imageSrcDesktop) {
		return null;
	}

	let component;

	if (config.isBanner) {
		component = (
			<>
				<div className="hidden md:block">
					<Image
						alt={config.alt || 'Shinesty Image'}
						layout="fill"
						objectFit="cover"
						objectPosition="center"
						height={config.imageHeightDesktop}
						src={config.imageSrcDesktop}
						width={config.imageWidthDesktop}
					/>
				</div>
				<div className="block md:hidden">
					<Image
						alt={config.alt || 'Shinesty Image'}
						layout="fill"
						objectFit="cover"
						objectPosition="center"
						height={config.imageHeightMobile}
						src={config.imageSrcMobile}
						width={config.imageWidthMobile}
					/>
				</div>
			</>
		);
	} else {
		component = (
			<>
				<div className="hidden md:block">
					<Image
						alt={config.alt || 'Shinesty Image'}
						height={config.imageHeightDesktop}
						objectFit="contain"
						src={config.imageSrcDesktop}
						width={config.imageWidthDesktop}
					/>
				</div>
				<div className="block md:hidden">
					<Image
						alt={config.alt || 'Shinesty Image'}
						height={config.imageHeightMobile}
						objectFit="contain"
						src={config.imageSrcMobile}
						width={config.imageWidthMobile}
					/>
				</div>
			</>
		);
	}

	if (config.isLink && config.anchorHref) {
		component = <Clickable linkToInternal={config.anchorHref}>{component}</Clickable>;
	}

	if (config.isBanner) {
		return <div className="w-full h-96 mb-8 relative">{component}</div>;
	}

	return <div className="flex items-center align-middle justify-center mb-8 p-4">{component}</div>;
};

ImageSection.propTypes = {
	config: PropTypes.object,
};

export default ImageSection;
