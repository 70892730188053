import Image from '/components/Image';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';

const TierCard = ({ tier }) => {
	return (
		<div className="h-full pb-10">
			<div className="flex flex-col rounded-lg text-center m-3">
				<div className="rounded-full bg-[#ffcc4e] w-16 h-16 flex justify-center items-center -mb-8 ml-2 z-20">
					<Typography className="relative -top-2 -left-[.5rem]" component="h4" variant="subhead-md">
						Spend <br /> ${tier.priceMin}
					</Typography>
				</div>
				<div className="shadow-md rounded-lg">
					<Image
						alt={tier.title}
						blurPreload={true}
						className="w-full m-auto rounded-t-lg z-10"
						height={690}
						priority={false}
						objectFit="contain"
						src={tier.imageSrcCover}
						width={690}
					/>
					<div className="flex flex-col flex-grow justify-center pb-3 h-[7rem] px-2 text-gray-600">
						<Typography component="h3" variant="heading-sm">
							{tier.title}
						</Typography>
						<div className="flex justify-center py-2">
							<Typography
								aria-hidden="true"
								className="line-through text-gray-500 mr-2"
								component="h3"
								variant="subtitle-md">
								${tier.priceMsrp}
							</Typography>
							<Typography component="h3" variant="subtitle-md">
								Free
							</Typography>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

TierCard.propTypes = {
	tier: PropTypes.object,
};

export default TierCard;
