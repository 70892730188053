import FlyerStandard from '/components/landing-page/flyers/FlyerStandard';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';

const FlyersStandard = ({ config }) => {
	return (
		<div className="container max-w-7xl mb-3 md:mb-4">
			{config.heading && (
				<Typography className="mb-2 text-center md:mb-4" component="h2" variant="subhead-lg">
					{config.heading}
				</Typography>
			)}
			<div className="md:flex">
				{config.flyers &&
					config.flyers.map((flyer, i) => {
						return <FlyerStandard flyer={flyer} key={i} />;
					})}
			</div>
		</div>
	);
};

FlyersStandard.propTypes = {
	config: PropTypes.object,
};

export default FlyersStandard;
