import PropTypes from 'prop-types';
import Typography from '/components/Typography';

const Author = ({ landingPage }) => {
	if (
		!landingPage.meta ||
		(!landingPage.meta.searchEngineAuthor && !landingPage.meta.searchEngineDate)
	) {
		return null;
	}

	return (
		<div className="max-w-7xl mb-8 mx-auto pl-4">
			{landingPage.meta.searchEngineAuthor && (
				<Typography component="div" variant="body">
					by {landingPage.meta?.searchEngineAuthor}
				</Typography>
			)}
			{landingPage.meta.searchEngineDate && (
				<Typography variant="body">{landingPage.meta.searchEngineDate}</Typography>
			)}
		</div>
	);
};

Author.propTypes = {
	landingPage: PropTypes.object,
};

export default Author;
