import Clickable from '/components/Clickable';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';

import classNames from '/utils/class-names';

const CTASection = ({ config }) => {
	if (!config) {
		return null;
	}

	const alignments = {
		center: 'justify-center',
		left: 'pl-4', //might not need padding
		right: 'justify-end pr-4', //might not need padding
	};

	return (
		<div
			className={classNames(
				'container flex items-center max-w-7xl mb-3',
				alignments[config.alignment] ? alignments[config.alignment] : alignments.left,
			)}>
			<Clickable linkToExternal={config.buttonHref} target="_blank">
				<Typography
					className="bg-primary px-8 py-4 text-white text-center md:w-max rounded-sm"
					component="div"
					variant="heading-xs">
					{config.buttonText}
				</Typography>
			</Clickable>
		</div>
	);
};

CTASection.propTypes = {
	config: PropTypes.object,
};

export default CTASection;
