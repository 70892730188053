import Article from '/components/lp/sections/Article';
import Assortment from '/components/lp/sections/Assortment';
import Author from '/components/lp/sections/Author';
import CTASection from '/components/lp/sections/CTASection';
import CategoryPreview from '/components/lp/sections/CategoryPreview';
import CollectionCarousel from '/components/lp/sections/CollectionCarousel';
import CollectionStandard from '/components/lp/sections/CollectionStandard';
import FlyersBanner from '/components/lp/sections/FlyersBanner';
import FlyersFeature from '/components/lp/sections/FlyersFeature';
import FlyersStandard from '/components/lp/sections/FlyersStandard';
import GiftCarousel from '/components/lp/sections/GiftCarousel';
import GoogleForm from '/components/lp/sections/GoogleForm';
import Hero from '/components/lp/sections/Hero';
import HotProductsAlgolia from '/components/lp/sections/HotProductsAlgolia';
import ImageGrid from '/components/lp/sections/ImageGrid';
import ImageSection from '/components/lp/sections/ImageSection';
import Klaviyo from '/components/lp/sections/Klaviyo';
import LinkList from '/components/lp/sections/LinkList';
import Markdown from '/components/lp/sections/Markdown';
import ProductList from '/components/lp/sections/ProductList';
import ProductSingle from '/components/lp/sections/ProductSingle';
import PropTypes from 'prop-types';
import SizeGuide from '/components/lp/sections/SizeGuide';
import Spotify from '/components/lp/sections/Spotify';
import TableOfContents from '/components/lp/sections/TableOfContents';
import TabulatedProductSection from '/components/lp/sections/TabulatedProductSection';
import TextCarousel from '/components/lp/sections/TextCarousel';
import TextWithImage from '/components/lp/sections/TextWithImage';
import Ticker from '/components/lp/sections/Ticker';
import Youtube from '/components/lp/sections/Youtube';

const LandingPageSection = ({ landingPage, section }) => {
	if (!section || !section.sectionType || section.sectionType === 'none') {
		return null;
	}

	if (section.sectionType === 'article') {
		return <Article config={section} />;
	}

	if (section.sectionType === 'author') {
		return <Author landingPage={landingPage} />;
	}

	if (section.sectionType === 'assortment') {
		return <Assortment config={section} />;
	}

	if (section.sectionType === 'category-preview') {
		return <CategoryPreview config={section} />;
	}

	if (section.sectionType === 'cta') {
		return <CTASection config={section} />;
	}

	if (section.sectionType === 'collection-carousel') {
		return <CollectionCarousel config={section} />;
	}

	if (section.sectionType === 'collection-standard') {
		return <CollectionStandard config={section} />;
	}

	if (section.sectionType === 'flyers-banner') {
		return <FlyersBanner config={section} />;
	}

	if (section.sectionType === 'flyers-feature') {
		return <FlyersFeature config={section} />;
	}

	if (section.sectionType === 'flyers-standard') {
		return <FlyersStandard config={section} />;
	}

	if (section.sectionType === 'gift-carousel') {
		return <GiftCarousel config={section} />;
	}

	if (section.sectionType === 'google-form') {
		return <GoogleForm config={section} />;
	}

	if (section.sectionType === 'hero') {
		return <Hero config={section} />;
	}

	if (section.sectionType === 'hot-products') {
		return <HotProductsAlgolia config={section} collection={'homepage-2023'} />;
	}

	if (section.sectionType === 'image') {
		return <ImageSection config={section} />;
	}

	if (section.sectionType === 'image-grid') {
		return <ImageGrid config={section} />;
	}

	if (section.sectionType === 'klaviyo-form') {
		return <Klaviyo config={section} />;
	}

	if (section.sectionType === 'link-list') {
		return <LinkList config={section} />;
	}

	if (section.sectionType === 'markdown') {
		return <Markdown config={section} />;
	}

	if (section.sectionType === 'product-list') {
		return <ProductList config={section} />;
	}

	if (section.sectionType === 'product-single') {
		return <ProductSingle config={section} />;
	}

	if (section.sectionType === 'size-guide') {
		return <SizeGuide config={section} />;
	}

	if (section.sectionType === 'spotify') {
		return <Spotify config={section} />;
	}

	if (section.sectionType === 'table-of-contents') {
		return <TableOfContents />;
	}

	if (section.sectionType === 'tabulated-products') {
		return <TabulatedProductSection config={section} />;
	}

	if (section.sectionType === 'text-with-image') {
		return <TextWithImage config={section} />;
	}

	if (section.sectionType === 'text-carousel') {
		return <TextCarousel config={section} />;
	}

	if (section.sectionType === 'ticker') {
		return <Ticker config={section} />;
	}

	if (section.sectionType === 'youtube') {
		return <Youtube config={section} />;
	}

	return null;
};

LandingPageSection.propTypes = {
	landingPage: PropTypes.object,
	section: PropTypes.object,
};

export default LandingPageSection;
