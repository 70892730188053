const kebabCase = (string) => {
	return string
		.replace(/[^a-zA-Z ]/g, '')
		.replace(/([a-z])([A-Z])/g, '$1-$2')
		.replace(/[\s_]+/g, '-')
		.toLowerCase();
};

module.exports = {
	kebabCase,
};
