import HotProductsAlgolia from '/components/lp/sections/HotProductsAlgolia';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';
import { useState } from 'react';

const TabulatedProductSection = ({ config }) => {
	const [activeCollection, setActiveCollection] = useState(config.groupOneCollection);

	if (!config) {
		return null;
	}

	return (
		<div className="container max-w-7xl mb-3 md:mb-4">
			{config.heading && (
				<Typography className="mb-2 text-center md:mb-4" component="h2" variant="subhead-lg">
					{config.heading}
				</Typography>
			)}
			<div className="grid grid-cols-3 max-w-lg mx-auto pb-4">
				<button
					className={classNames(
						'uppercase font-semibold border-b-4',
						config.groupOneCollection == activeCollection ? 'border-primary' : 'border-gray-500',
					)}
					onClick={() => setActiveCollection(config.groupOneCollection)}>
					{config.groupOneName}
				</button>
				<button
					className={classNames(
						'uppercase font-semibold border-b-4',
						config.groupTwoCollection == activeCollection ? 'border-primary' : 'border-gray-500',
					)}
					onClick={() => setActiveCollection(config.groupTwoCollection)}>
					{config.groupTwoName}
				</button>
				<button
					className={classNames(
						'uppercase font-semibold border-b-4',
						config.groupThreeCollection == activeCollection ? 'border-primary' : 'border-gray-500',
					)}
					onClick={() => setActiveCollection(config.groupThreeCollection)}>
					{config.groupThreeName}
				</button>
			</div>
			<HotProductsAlgolia config={{ isCarousel: false }} collection={activeCollection} />
		</div>
	);
};

TabulatedProductSection.propTypes = {
	config: PropTypes.object,
};

export default TabulatedProductSection;
