import Carousel from '/components/Carousel';
import PropTypes from 'prop-types';
import Stars from '/components/Stars';
import Typography from '/components/Typography';

const TextCarousel = ({ config }) => {
	if (!config.slides || config.slides.length === 0) {
		return null;
	}

	return (
		<div className="mb-10 max-w-3xl mx-auto">
			<Carousel dots={true} slidesToShow={1} infinite={true}>
				{config.slides.map((slide, slideIndex) => {
					return (
						<div key={slideIndex}>
							<div className="max-w-2xl flex-col items-center justify-center mx-auto text-center px-6 lg:px-0">
								{slide.showStars && (
									<div className="flex justify-center mb-2">
										<Stars rating={slide.numberOfStars || 5} variant="large" />
									</div>
								)}

								{slide.text && (
									<Typography className="leading-8 lg:leading-9 px-4 text-2xl" component="div">
										{slide.text}
									</Typography>
								)}
								{slide.subtext && (
									<Typography className="italic mt-4" component="div" variant="subtitle-md">
										{slide.subtext}
									</Typography>
								)}
							</div>
						</div>
					);
				})}
			</Carousel>
		</div>
	);
};

TextCarousel.propTypes = {
	config: PropTypes.object,
};

export default TextCarousel;
