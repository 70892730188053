import Clickable from '/components/Clickable';
import Markdown from '/components/Markdown';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';

const Article = ({ config }) => {
	return (
		<div className="container justify-center max-w-7xl my-4 p-4">
			{config.heading && (
				<Typography
					className="text-center md:text-left mb-4 md:grid md:grid-cols-2 md:mb-6"
					component="h1"
					variant="subtitle-lg">
					{config.heading}
				</Typography>
			)}
			<div className="md:gap-4 md:grid md:grid-cols-2">
				{config.content && <Markdown content={config.content} />}
				{config.ctaText && config.ctaDestination && (
					<Clickable linkToExternal={config.ctaDestination} target="_blank">
						<Typography
							className="bg-primary px-8 py-4 text-white text-center md:w-max rounded-sm"
							component="div"
							variant="heading-xs">
							{config.ctaText}
						</Typography>
					</Clickable>
				)}
			</div>
		</div>
	);
};

Article.propTypes = {
	config: PropTypes.object,
};

export default Article;
