import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';

const Ticker = ({ config }) => {
	if (!config.content) {
		return null;
	}
	return (
		<div
			className={classNames(
				'mb-4 p-4 w-full text-center',
				config.backgroundColor ? `bg-[${config.backgroundColor}]` : 'bg-primary',
			)}>
			<Typography className="!leading-4" component="div" variant="heading-xs">
				{config.content}
			</Typography>
			{/* TODO: Figure out a better way to support all colors? - send mac color codes for themes */}
			{false && <div className="bg-[#FACC15]" />}
		</div>
	);
};

Ticker.propTypes = {
	config: PropTypes.object,
};

export default Ticker;
