import PropTypes from 'prop-types';

const HeroVideo = ({ config }) => {
	if (!config || !config.videoSrc) {
		return null;
	}

	return (
		<video
			aria-label="benefits of men's and women's underwear such as the built-in pouch, satisfaction guarantee, soft fabric and variety of print options"
			autoPlay={'autoplay'}
			className="h-full w-full md:pl-4"
			controlsList="nodownload"
			id="indexVideo"
			loop
			muted
			playsInline
			poster={config.videoPosterSrc}
			preload="metadata">
			<source src={config.videoSrc} type="video/mp4" />
		</video>
	);
};

HeroVideo.propTypes = {
	config: PropTypes.object,
};

export default HeroVideo;
