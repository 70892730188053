import Clickable from '/components/Clickable';
import Image from '/components/Image';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';

const FlyerStandard = ({ flyer }) => {
	return (
		<div className={classNames('flex flex-col py-2 px-4', 'md:relative md:w-full')}>
			<Clickable
				className={classNames('block h-[375px] relative w-full', 'md:h-[320px]')}
				linkToInternal={flyer.anchorHref}
				heapEventData={{
					'click-anchor-href': flyer.anchorHref,
					'click-title': flyer.title,
				}}
				heapEventName="flyers-standard-image-click">
				<div className="md:hidden">
					<Image
						alt="image of boxer with a shinesty package in the center"
						layout="fill"
						objectFit="cover"
						objectPosition="center"
						src={flyer.imageSrcMobile}
					/>
				</div>

				<div className="hidden md:block">
					<Image
						alt="image of boxer with a shinesty package in the center"
						layout="fill"
						objectFit="cover"
						objectPosition="center"
						src={flyer.imageSrcDesktop}
					/>
				</div>
			</Clickable>

			<div className="md:absolute md:bottom-10 md:left-10 md:max-w-[400px]">
				<Typography
					className={classNames('mt-4 text-center', 'md:hidden')}
					component="h2"
					variant="heading-md">
					{flyer.titleMobile}
				</Typography>

				<Typography
					className={classNames('hidden', flyer.classText, 'md:block')}
					variant="heading-xs">
					{flyer.titleBonus}
				</Typography>

				<Typography
					className={classNames('hidden max-w-[75%] mb-2 text-left', flyer.classText, 'md:block')}
					component="h2"
					variant="heading-md">
					{flyer.titleDesktop}
				</Typography>

				{flyer.subtitleIsHtml !== true && (
					<Typography
						className={classNames('text-center', flyer.classText, 'md:text-left')}
						component="p"
						variant="body">
						{flyer.subtitle}
					</Typography>
				)}

				{flyer.subtitleIsHtml === true && <>{flyer.subtitle}</>}

				<Clickable
					className={classNames(
						'bg-[#2F3337] flex font-bold h-[44px] items-center justify-center mt-4 rounded-sm text-center text-white tracking-widest uppercase',
						'md:h-auto md:inline-block md:py-3 md:px-5',
					)}
					heapEventData={{
						'click-anchor-href': flyer.anchorHref,
						'click-title': flyer.title,
					}}
					heapEventName="flyers-standard-button-click"
					linkToInternal={flyer.anchorHref}>
					{flyer.anchorText}
				</Clickable>
				{flyer.secondaryLink && (
					<Clickable
						className={classNames(
							'bg-[#2F3337] flex font-bold h-[44px] items-center justify-center mt-4 rounded-sm text-center text-white tracking-widest uppercase',
							'md:h-auto md:inline-block md:p-3',
						)}
						heapEventData={{
							'click-anchor-href': flyer.secondaryLink,
							'click-title': flyer.title,
						}}
						heapEventName="flyers-standard-button-click"
						linkToInternal={flyer.secondaryLink}>
						{flyer.secondaryButtonText}
					</Clickable>
				)}
			</div>
		</div>
	);
};

FlyerStandard.propTypes = {
	flyer: PropTypes.object,
};

export default FlyerStandard;
