import Image from '/components/Image';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';

const ImageGrid = ({ config }) => {
	return (
		<div className="container justify-center max-w-7xl mb-4 px-4">
			{config.headline && (
				<Typography className="mb-4 text-center" component="h1" variant="subhead-lg">
					{config.headline}
				</Typography>
			)}
			{config.subHeadline && (
				<Typography className="text-center mb-4" component="p" variant="subtitle-md">
					{config.subHeadline}
				</Typography>
			)}
			{config.images && config.images.length > 0 && (
				<div className="flex flex-col justify-center items-center md:flex-row">
					{config.images.map((image, imageIndex) => {
						return (
							<div className="flex flex-col mx-8" key={imageIndex}>
								<Image
									src={image.imageSrcDesktop}
									alt={image.altText}
									height={200}
									width={200}
									objectFit="cover"
								/>
								<Typography
									className="my-2 md:my-4 text-center"
									component="p"
									variant="subtitle-sm">
									{image.title}
								</Typography>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};

ImageGrid.propTypes = {
	config: PropTypes.object,
};

export default ImageGrid;
