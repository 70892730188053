import Carousel from '/components/Carousel';
import Image from '/components/Image';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';

const ProductCarousel = ({
	centerMode = false,
	centerPadding = -25,
	dots = true,
	product,
	showTitle = true,
	size = 400,
}) => {
	const settings = {
		dots,
		centerPadding,
		centerMode,
		infinite: true,
		slidesToScroll: 1,
		slidesToShow: 1,
	};

	const titleClass = showTitle ? 'md:hidden' : 'hidden';

	return (
		<div className="">
			<div className={titleClass}>
				<Typography component="h1" variant="subtitle-md">
					{product.titles[0] || ''}
				</Typography>
				{product.titles[1] && (
					<Typography component="div" variant="body">
						{product.titles[1]}
					</Typography>
				)}
			</div>
			<Carousel {...settings}>
				{product.images.slice(0, 4).map((image, i) => {
					return (
						<figure className="relative" key={i}>
							<div className="flex justify-center relative w-full" style={{ height: size }}>
								<Image
									alt={product.title}
									blurPreload={true}
									height={size}
									objectFit="contain"
									src={image.src}
									width={size}
								/>
							</div>
						</figure>
					);
				})}
			</Carousel>
		</div>
	);
};

ProductCarousel.propTypes = {
	centerMode: PropTypes.bool,
	centerPadding: PropTypes.number,
	dots: PropTypes.bool,
	product: PropTypes.object.isRequired,
	showTitle: PropTypes.bool,
	size: PropTypes.number,
};

export default ProductCarousel;
