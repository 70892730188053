import ProductListing from '/components/collections/ProductListing';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import { getCollection } from '/services/algolia';
import { logTryCatch } from '/utils/logging';
import { useEffect, useState } from 'react';
const CollectionStandard = ({ config, productCardClassNames }) => {
	let pageSize = 12;
	const [algoliaResponse, setAlgoliaRepsonse] = useState();
	useEffect(() => {
		async function getProducts() {
			const algoliaResponse = await getCollection(config.collectionHandle);
			setAlgoliaRepsonse(algoliaResponse);
		}
		getProducts();
	}, []);
	try {
		if (typeof config.pageSize === 'string') {
			pageSize = parseInt(config.pageSize);
		}
	} catch (e) {
		logTryCatch('could not parse page size');
	}

	return (
		<div className="container max-w-7xl mb-4">
			{config.heading && (
				<Typography className="text-center" component="h1" variant="subhead-lg">
					{config.heading}
				</Typography>
			)}
			{config.collectionHandle && algoliaResponse && (
				<ProductListing
					algoliaResponse={algoliaResponse}
					facets={{}}
					pageSize={pageSize}
					pageType="landing"
					productCardClassNames={productCardClassNames}
					productCount={pageSize}
				/>
			)}
		</div>
	);
};

CollectionStandard.propTypes = {
	config: PropTypes.object,
	productCardClassNames: PropTypes.string,
};

export default CollectionStandard;
