import Clickable from '/components/Clickable';
import Icon from '/components/IconUpdated';
import Image from '/components/Image';
import Loading from '/components/Loading';
import Price from '../Price';
import ProductCardQuickAdd from '/components/collections/ProductCardQuickAdd';
import ProductType from '/components/products/show/product-form/ProductType';
import PropTypes from 'prop-types';
import ReviewStars from '/components/ReviewStars';
import Typography from '/components/Typography';
import { checkProductTags } from '/utils/product-tags';
import classNames from '/utils/class-names';
import dynamic from 'next/dynamic';
import { formatMonetaryValue } from '/utils/format';
import { getProductCardDetails } from '/services/static/product-card';
import { getProductSticker } from '/services/static/product-sticker';
import { parseGid } from '/utils/graph-ql';
import { reactChildren } from '/utils/prop-types';
import { useState } from 'react';

const QuickShop = dynamic(() => import('/components/modals/QuickShop'));

const ProductCard = ({
	abVariantCards,
	forceLoading = false,
	heapLocation = '',
	onClick,
	product,
	priority = false,
	variant = 'default',
	rank,
	source = 'algolia',
	showQuickShop,
}) => {
	const [quickShopModalOpen, setQuickShopModalOpen] = useState(false);
	const [showQuickAdd, setShowQuickAdd] = useState(false);

	const [quickShopHandle, setQuickShopHandle] = useState(product?.handle);

	const handleModalState = (handle) => {
		setQuickShopHandle(handle);
		setQuickShopModalOpen((prev) => !prev);
	};

	if (!product) {
		return null;
	}

	if (product?.endcap) {
		return (
			<div className="p-2 md:px-4 flex flex-col rounded-lg justify-center items-center">
				<div className="mb-2 opacity-10 self-start animate-[bounce-slow_8s_ease-in-out_infinite]">
					<Image
						alt={product.endcapBody}
						height={76}
						src="https://cdn.shinesty.com/2023-09-21/cloud-gray.svg"
						width={76}
						objectFit="contain"
					/>
				</div>
				<Typography variant="subtitle-lg">{product.endcapCopy}.</Typography>
				<div className="mt-2 opacit	y-20 self-end">
					<Image
						alt={product.endcapCta}
						height={34}
						src="https://cdn.shinesty.com/2023-09-21/cloud-gray.svg"
						width={34}
						objectFit="contain"
					/>
				</div>
				<Typography className="mt-8 md:mt-10 text-right" variant="subtitle-lg">
					{product.endcapCta}
				</Typography>
				<Clickable
					color="gray-600"
					className="self-end mt-4 md:mt-10 whitespace-nowrap bg-white border-gray-600 border-[3px] py-2 rounded-full text-gray-600 px-4 font-bold uppercase tracking-widest"
					onClick={null}
					linkToInternal={`${product.endcapDestination}`}>
					Shop all
				</Clickable>
			</div>
		);
	}

	const isQuickAddTest =
		abVariantCards === 'show-quick-add' &&
		(variant === 'default' || variant === 'supercollection') &&
		!product?.handle?.includes('pack');

	if (forceLoading) {
		return (
			<div className="flex h-[396px]  md:h-[556px] items-center justify-center w-[348px]">
				<Loading height="32px" width="32px" className="ml-2" />
			</div>
		);
	}

	// early catch for passing nulls to product cards for place holders
	if (!product) {
		return <div className="h-[656px] w-[348px]" />;
	}

	const titles = product?.title?.split('|') || [];

	// some apis return product ids as numbers -- cast them as strings
	// because ids are strings and not numbers in real life.
	let productId = typeof product.id === 'number' ? product.id.toString() : product.id;

	const settingsLookup = {
		catalog: {
			classNameCardContainer:
				'rounded-lg px-4 mb-10 group flex flex-col justify-between w-[47%] flex-initial lg:w-[23%] rounded-lg bg-gray-100  mx-1 md:m-2 py-4',
			classNameClickable: 'flex flex-col flex-grow items-stretch rounded-lg',
			classNameImageContainer:
				'h-[230px] md:h-[350px] flex flex-col justify-center relative overflow-hidden items bg-gray-100 rounded-lg',
			classNameQuickShopContainer:
				'py-3 w-full flex justify-center items-center gap-4 text-primary uppercase 	bg-gray-100',
			showSticker: false,
			showSubtitle: false,
		},
		default: {
			classNameCardContainer:
				'rounded-t-lg px-2 mb-10 group flex-col justify-between w-1/2 md:w-[33%] flex-initial lg:w-[25%]',
			classNameClickable: 'flex flex-col flex-grow items-stretch',
			classNameImageContainer:
				'h-[247px] md:h-[426px] flex flex-col justify-center relative overflow-hidden items bg-white',
			classNameInfoContainer: 'flex flex-col flex-grow justify-between',
			classNamePriceAndStarsContainer:
				'flex flex-col-reverse flex-grow justify-end md:flex-row md:flex-wrap-reverse md:justify-between md:items-start',
			classNameQuickShopContainer:
				'py-3 w-full flex justify-center items-center gap-4 text-primary uppercase bg-white',
			imageSizes: {
				srcSets: [
					{ maxWidth: '768px', imageWidth: '50vw' },
					{ maxWidth: '1024px', imageWidth: '33vw' },
				],
				defaultSize: '25vw',
			},
			showReviewStars: true,
			showSticker: true,
			showSubtitle: true,
			showQuickShop: typeof showQuickShop !== 'undefined' ? showQuickShop : true,
			stickerSize: 60,
			titleText: 'body',
		},
		horizontal: {
			classNameCardContainer: 'mb-8 group flex flex-row w-full',
			classNameClickable: 'flex flex-grow',
			classNameImageContainer:
				'h-[100px] relative overflow-hidden w-[100px] flex-none flex items-center justify-center mr-3',
			classNameInfoContainer: 'flex flex-col flex-grow justify-between',
			classNamePriceAndStarsContainer: 'flex justify-between items-center',
			showQuickShop: typeof showQuickShop !== 'undefined' ? showQuickShop : false,
			stickerSize: 40,
		},
		mini: {
			classNameCardContainer:
				'group flex flex-col justify-between md:w-auto flex-initial lg:max-w-[18rem] h-full text-left',
			classNameImageContainer:
				'h-[285px] flex flex-col justify-center relative overflow-hidden bg-white',
			showReviewStars: false,
			showSticker: false,
			showSubtitle: false,
			titleText: 'body-heavy',
		},
		supercollection: {
			classNameCardContainer:
				'group flex-col justify-between flex-initial lg:max-w-[18rem] h-full p-1',
			classNameImageContainer:
				'h-[247px] md:h-[426px] flex flex-col justify-center relative rounded-xl bg-gray-200',
			classNameTitle: 'font-semibold',
			showSticker: false,
			showSubtitle: false,
		},
	};

	const settings = {
		...settingsLookup['default'],
		...settingsLookup[variant],
	};

	const sticker = getProductSticker(product);

	const customProductCardDetails = getProductCardDetails(product.title, product.id, heapLocation);
	const isSuperCollection = heapLocation === 'supercollection';

	const productImage =
		customProductCardDetails.imageSrc ||
		product.product_image ||
		product.image_link ||
		(product.images && product.images[0] && product.images[0].src);

	const productLink = customProductCardDetails.link || `/products/${product.handle}`;

	settings.showQuickShop =
		settings.showQuickShop &&
		product?.handle &&
		// TODO - Bruce and MAC added this to support non-algolia quick shop. Please confirm. Can we get named tags from Shopify?
		(product?.named_tags?.feature?.includes(' Quick Shop') ||
			product?.tags?.includes('Quick Shop') ||
			product?.tags?.includes('feature: Quick Shop'));

	const ClickToDetails = ({ children, className }) => (
		<Clickable
			className={className}
			googleEventData={{
				product: product,
				rank: rank,
			}}
			googleEventName="trackProductClick"
			heapEventName="Product Card Click"
			heapEventData={{
				Location: heapLocation,
				Price: formatMonetaryValue(product.price), // TODO: sale pricing?
				Product: product.handle,
				Position: rank,
				'Product Type': product.product_type,
				objectId: product.objectID,
			}}
			linkToInternal={productLink}
			onClick={onClick}>
			{children}
		</Clickable>
	);

	ClickToDetails.propTypes = {
		children: reactChildren.isRequired,
		className: PropTypes.string,
	};

	const getShopText = () => {
		const productTypeLookup = {
			'Conjured Subscription': 'Subscribe Now',
			Gifts: 'Shop Gift Now',
			Subscription: 'Subscribe Now',
		};

		if (productTypeLookup[product.product_type]) {
			return productTypeLookup[product.product_type];
		}

		if (checkProductTags(product.tags, 'Underwear Packs')) {
			return 'Start Packing';
		}

		return 'View Product';
	};

	const showCompareAtPrice =
		product?.compare_at_price > product?.variants_min_price &&
		product?.product_type !== 'Conjured Subscription' &&
		product?.product_type !== 'Gift Cards';

	let productPrice = product.variants_min_price || product.price;
	let compareAtPrice = product.compare_at_price;

	if (source === 'shopify') {
		if (product.compareAtPrices) {
			compareAtPrice = product.compareAtPrices[0];
		}

		productId = parseGid(product.id);
		productPrice = product.prices && product.prices[0];
	}

	return (
		<div
			className={classNames(
				settings.classNameCardContainer,
				showQuickAdd && settings.showQuickShop && isQuickAddTest
					? 'shadow-xl rounded-t-xl'
					: 'flex',
			)}
			onMouseOver={() => setShowQuickAdd(true)}
			onMouseLeave={() => setShowQuickAdd(false)}>
			<div className={settings.classNameClickable}>
				<ClickToDetails>
					{productImage && (
						<div className={settings.classNameImageContainer}>
							{['catalog', 'mini'].includes(variant) && (
								<div className="absolute right-0 text-right top-0 z-20">
									<ProductType productType={product?.product_type} />
								</div>
							)}
							{settings.showSticker && sticker && (
								<div className="absolute right-0 top-0 z-10">
									<Image
										alt="product sticker"
										height={settings.stickerSize}
										src={sticker}
										width={settings.stickerSize}
									/>
								</div>
							)}
							<Image
								alt={product.title}
								blurPreload={true}
								layout="fill"
								objectFit="contain"
								priority={priority}
								sizes={settings.imageSizes}
								src={productImage}
								// width={settings.imageSize}
								// height={settings.imageSize * 1.5}
							/>
						</div>
					)}
				</ClickToDetails>
				{/* fix */}
				{variant !== 'horizontal' && (
					<div className="h-[42px] w-full">
						{settings.showQuickShop ? (
							<>
								{abVariantCards !== 'show-quick-add' && (
									<Clickable
										size="xs"
										className={`py-3 w-full flex justify-center items-center gap-4 text-primary uppercase`}
										heapEventName="Quickshop Open"
										heapEventData={{
											handle: product?.handle,
										}}
										onClick={() => handleModalState(product.handle)}>
										<Icon name="quickshop" height="16px" width="16px" />
										<Typography
											variant="micro"
											component="div"
											className="font-semibold tracking-widest uppercase">
											Quick Shop
										</Typography>
									</Clickable>
								)}
								{quickShopModalOpen && quickShopHandle && (
									<QuickShop
										active={quickShopModalOpen}
										handleModalState={handleModalState}
										productHandle={quickShopHandle}
									/>
								)}
							</>
						) : (
							<ClickToDetails className={settings.classNameQuickShopContainer}>
								<Typography
									variant="micro"
									component="div"
									className="font-semibold tracking-widest uppercase">
									{getShopText()}
								</Typography>
							</ClickToDetails>
						)}
					</div>
				)}

				<ClickToDetails className={settings.classNameInfoContainer}>
					<div>
						<Typography className="font-semibold" component="h3" variant="body">
							{customProductCardDetails?.alternateTitle || titles[0]}
						</Typography>
						{settings.showSubtitle && titles[1] && (
							<div className="h-11 overflow-hidden text-ellipsis">
								<Typography component="h3" variant="small">
									{titles[1]}
								</Typography>
							</div>
						)}
					</div>
				</ClickToDetails>

				<div className={settings.classNamePriceAndStarsContainer}>
					{productPrice && (
						<Typography className={settings.classNamePriceContainer} component="p" variant="body">
							{(showCompareAtPrice && (
								<>
									{product?.named_tags?.feature?.includes(' from') && 'From '}
									<span aria-hidden="true" className="line-through text-gray-500 italic">
										<Price
											format={'explicit'}
											price={compareAtPrice}
											internationalPricing={product?.international_pricing?.compare_at_price}
										/>{' '}
									</span>
									<span className="text-primary font-semibold">
										<Price
											format={'explicit'}
											price={productPrice}
											product={product}
											internationalPricing={product?.international_pricing?.price}
										/>
									</span>
								</>
							)) || (
								<span>
									{product?.named_tags?.feature?.includes(' from') && 'From '}
									<Price
										price={productPrice}
										product={product}
										internationalPricing={product?.international_pricing?.price}
									/>
								</span>
							)}
						</Typography>
					)}
					{settings.showReviewStars && product?.id && (
						<div className="self-baseline">
							<ReviewStars productId={productId} variant="small" />
						</div>
					)}
				</div>
				{settings.showQuickShop && isQuickAddTest && (
					<button
						className="md:hidden flex items-stretch align-middle justify-around"
						onClick={() => setShowQuickAdd(!showQuickAdd)}>
						<div>
							<Typography variant="body" className="font-semibold py-1">
								Add To Cart
							</Typography>
						</div>
						<Typography variant="subhead-sm">{showQuickAdd ? '-' : '+'}</Typography>
					</button>
				)}
			</div>
			{showQuickAdd && isQuickAddTest && settings.showQuickShop && (
				<ProductCardQuickAdd product={product} isSuperCollection={isSuperCollection} />
			)}
		</div>
	);
};

ProductCard.propTypes = {
	abVariantCards: PropTypes.string,
	forceLoading: PropTypes.bool,
	heapLocation: PropTypes.string,
	onClick: PropTypes.func,
	priority: PropTypes.bool,
	product: PropTypes.object,
	rank: PropTypes.number,
	showQuickShop: PropTypes.bool,
	source: PropTypes.string,
	variant: PropTypes.string,
};

export default ProductCard;
