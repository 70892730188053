import Clickable from '/components/Clickable';
import Markdown from '/components/Markdown';
import PropTypes from 'prop-types';

import classNames from '/utils/class-names';

const HeroText = ({ config }) => {
	const alignments = {
		center: 'flex flex-col items-center justify-center mt-4 md:mx-4 text-center',
		left: 'xl:justify-start xl:text-left',
		right: 'xl:justify-end xl:text-left',
	};
	const buttonAlignments = {
		center: '',
		left: 'justify-center xl:justify-start',
		right: 'justify-center xl:justify-start',
	};

	return (
		<div
			className={classNames(
				config.heroVersion === 'v2'
					? alignments[config.alignment]
						? alignments[config.alignment]
						: alignments.left
					: 'flex flex-col items-left justify-center md:ml-10 md:mt-0 md:text-left mt-4 mx-4 text-center',
			)}>
			<Markdown content={config.content} />

			{config.buttons && config.buttons.length > 0 && (
				<div
					className={classNames(
						'flex gap-4 md:mt-4 mt-2',
						config.heroVersion === 'v2'
							? buttonAlignments[config.alignment]
								? buttonAlignments[config.alignment]
								: buttonAlignments.left
							: 'justify-center md:justify-start',
					)}>
					{config.buttons.map((button, i) => {
						return (
							<Clickable
								className="bg-primary font-bold px-6 py-3 rounded-sm text-base text-white tracking-widest uppercase"
								heapEventData={{
									'click-anchor-href': button.anchorHref,
									'click-text': button.text,
								}}
								heapEventName="hero-button-click"
								key={i}
								linkToInternal={button.anchorHref}>
								{button.text}
							</Clickable>
						);
					})}
				</div>
			)}
		</div>
	);
};

HeroText.propTypes = {
	config: PropTypes.object,
};

export default HeroText;
