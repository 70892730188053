import HeroImage from '/components/lp/sections/hero/HeroImage';
import HeroText from '/components/lp/sections/hero/HeroText';
import HeroVideo from '/components/lp/sections/hero/HeroVideo';
import PropTypes from 'prop-types';

const HeroV1 = ({ config }) => {
	if (!['image', 'video'].includes(config.heroType)) {
		return null;
	}

	if (config.heroType == 'video') {
		return (
			<div className="bg-white grid grid-cols-1 md:grid-cols-2 mb-6 md:min-h-[50vh] max-w-7xl m-auto">
				<div className="h-full w-full">
					<HeroVideo config={config} />
				</div>
				<HeroText config={config} />
			</div>
		);
	}

	return <HeroImage config={config} />;
};

HeroV1.propTypes = {
	config: PropTypes.object,
};
export default HeroV1;
