import ProductCard from '/components/collections/ProductCard';
import ProductCarousel from '/components/lp/sections/shared/ProductCarousel';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import { getProductById } from '/services/products';

import { useEffect, useState } from 'react';

const ProductList = ({ config }) => {
	const [products, setProducts] = useState();

	useEffect(() => {
		const fetchProducts = async () => {
			const promises = config.products.map((product) => {
				return getProductById(product.productId);
			});

			const allProducts = await Promise.all(promises);
			setProducts(allProducts);
		};

		if (config.products && config.products.length > 0) {
			fetchProducts();
		}
	}, [config.products]);

	return (
		<div className="container max-w-7xl mb-12 w-screen md:w-auto px-4 md:px-0">
			{config.heading && (
				<Typography className="mb-2 text-center md:mb-6" component="h2" variant="subhead-lg">
					{config.heading}
				</Typography>
			)}
			{!config.isCarousel && products && products.length > 0 && (
				<div className="flex-1">
					<div className="flex flex-wrap justify-start md:m-2">
						{products.map((product, i) => {
							return (
								<ProductCard key={i} priority={i < 5} product={product} rank={i} source="shopify" />
							);
						})}
					</div>
				</div>
			)}
			{config.isCarousel === true && products && products.length > 0 && (
				<ProductCarousel products={products} />
			)}
		</div>
	);
};

ProductList.propTypes = {
	config: PropTypes.object,
};

export default ProductList;
