import PropTypes from 'prop-types';
import Typography from '/components/Typography';

import { kebabCase } from '/utils/string';
import { useEffect, useState } from 'react';

const TableOfContents = () => {
	const [tableEntries, setTableEntries] = useState([]);

	useEffect(() => {
		const headings = Array.from(document.getElementsByTagName('h2'));

		if (!headings || !Array.isArray(headings)) {
			return;
		}

		const entries = [];
		headings.forEach((heading) => {
			if (heading.hasAttribute('_ignoretableofcontents')) {
				return;
			}

			const id = kebabCase(heading.textContent);
			heading.setAttribute('id', id);

			entries.push({
				id,
				title: heading.textContent,
			});
		});

		setTableEntries(entries);
	}, []);

	return (
		<div className="self-start mb-4 p-4 text-left bg-gray-100 border-1 md:w-max w-full">
			<Typography className="mb-2" component="div" variant="small">
				On this page
			</Typography>
			<ul>
				{tableEntries.map((entry, entryIndex) => {
					return (
						<>
							<li className="list-disc md:list-none md:ml-0 md:w-max ml-4 w-full">
								<a href={`#${entry.id}`} key={entryIndex}>
									<Typography
										className="font-semibold hover:underline hover:underline-offset-2"
										component="div"
										variant="body">
										{entry.title}
									</Typography>
								</a>
							</li>
						</>
					);
				})}
			</ul>
		</div>
	);
};

TableOfContents.propTypes = {
	config: PropTypes.object,
};

export default TableOfContents;
