import Clickable from '/components/Clickable';
import Image from '/components/Image';
import Markdown from '/components/Markdown';
import PropTypes from 'prop-types';

const HeroImage = ({ config }) => {
	if (!config.imageSrcDesktop || !config.imageSrcMobile) {
		return null;
	}

	return (
		<div className="mb-4 md:mb-12 md:relative">
			<div className="h-[30vh] hidden md:block md:h-[45vh] lg:h-[60vh] relative">
				<Image
					alt="underwear hero"
					layout="fill"
					objectFit="cover"
					objectPosition="right"
					src={config.imageSrcDesktop}
				/>
			</div>
			<div className="h-[55vh] relative md:hidden">
				<Image
					alt="underwear hero"
					layout="fill"
					objectFit="cover"
					objectPosition="left"
					src={config.imageSrcMobile}
				/>
			</div>
			<div className="p-4 md:absolute md:flex md:h-full md:items-center md:left-[1vw] md:max-w-xl md:top-0 md:w-full lg:left-[8vw] 2xl:left-[12vw]">
				<div className="text-center md:text-left md:text-white">
					<div className="my-2">
						<Markdown content={config.content} />
					</div>
					<div className="flex justify-center md:justify-start gap-4">
						{config.buttons && config.buttons.length > 0 && (
							<div className="flex gap-4 justify-center md:justify-start">
								{config.buttons.map((button, i) => {
									return (
										<Clickable
											className="bg-primary md:bg-white font-bold px-6 py-3 rounded-sm text-base text-white md:text-primary tracking-widest uppercase"
											heapEventData={{
												'click-anchor-href': button.anchorHref,
												'click-text': button.text,
											}}
											heapEventName="hero-button-click"
											key={i}
											linkToInternal={button.anchorHref}>
											{button.text}
										</Clickable>
									);
								})}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default HeroImage;

HeroImage.propTypes = {
	config: PropTypes.object,
};
