import Carousel from '/components/Carousel';
import PropTypes from 'prop-types';
import TierCard from '/components/lp/sections/gift-carousel/TierCard';
import Typography from '/components/Typography';
import { useGlobalStore } from '/state/global-context';
const GiftCarousel = ({ config }) => {
	const globalStore = useGlobalStore();
	const promotion = globalStore.getPromotionalConfig();
	if (
		!promotion ||
		promotion.promotionType !== 'stacking-gifts' ||
		!promotion.tiers ||
		promotion.tiers.length === 0
	) {
		return null;
	}

	return (
		<div className="text-left md:max-w-[80vw] m-auto">
			{config.title && (
				<div className="mx-5">
					<Typography className="text-center md:mb-2" component="h3" variant="subhead-lg">
						{config.title}
					</Typography>
				</div>
			)}

			<Carousel
				className="gray"
				slidesToShow={5}
				responsive={[
					{
						breakpoint: 1536,
						settings: {
							slidesToShow: 6,
						},
					},
					{
						breakpoint: 1024,
						settings: {
							slidesToShow: 1,
						},
					},
					{
						breakpoint: 640,
						settings: {
							slidesToShow: 1,
							centerMode: true,
							infinite: true,
							dots: true,
							autoplay: true,
						},
					},
				]}>
				{promotion.tiers.map((tier, i) => {
					return <TierCard key={i} tier={tier} />;
				})}
			</Carousel>
		</div>
	);
};

GiftCarousel.propTypes = {
	config: PropTypes.object,
};

export default GiftCarousel;
