import ProductCard from '/components/collections/ProductCard';
import ProductCarousel from '/components/lp/sections/shared/ProductCarousel';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import { getProductsInAssortment } from '/services/subscriptions';

import { useEffect, useState } from 'react';

const Assortment = ({ config }) => {
	const [products, setProducts] = useState();

	useEffect(() => {
		async function getProducts() {
			const productsResponse = await getProductsInAssortment(config.assortmentId);
			setProducts(productsResponse);
		}

		if (config.assortmentId) {
			getProducts();
		}
	}, [config.assortmentId]);

	return (
		<div className="container max-w-7xl mb-3 md:mb-4">
			{config.heading && (
				<Typography className="mb-2 text-center md:mb-6" component="h2" variant="subhead-lg">
					{config.heading}
				</Typography>
			)}
			{!config.isCarousel && products && products.length > 0 && (
				<div className="flex flex-wrap justify-start md:m-2">
					{products.map((product, i) => {
						return (
							<ProductCard key={i} priority={i < 5} product={product} rank={i} source="shopify" />
						);
					})}
				</div>
			)}
			{config.isCarousel === true && products && products.length > 0 && (
				<ProductCarousel products={products} />
			)}
		</div>
	);
};

Assortment.propTypes = {
	config: PropTypes.object,
};

export default Assortment;
