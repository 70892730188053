import FlyerFeature from '/components/landing-page/flyers/FlyerFeature';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';

const FlyersFeature = ({ config }) => {
	return (
		<div className="container max-w-7xl mb-3 md:mb-4">
			{config.heading && (
				<Typography className="mb-2 text-center md:mb-4" component="h2" variant="subhead-lg">
					{config.heading}
				</Typography>
			)}
			<div className={classNames('flex flex-col items-center justify-center', 'md:flex-row')}>
				{config.flyers &&
					config.flyers.map((flyer, i) => {
						return <FlyerFeature flyer={flyer} key={i} />;
					})}
			</div>
		</div>
	);
};

FlyersFeature.propTypes = {
	config: PropTypes.object,
};

export default FlyersFeature;
