import Clickable from '/components/Clickable';
import Icon from '/components/IconUpdated';
import Image from '/components/Image';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';

const FlyerFeature = ({ flyer }) => {
	return (
		<div className="flex flex-col my-2 px-4 relative w-full">
			<Clickable
				className="block relative w-full aspect-square"
				heapEventData={{
					'click-anchor-href': flyer.anchorHref,
					'click-subtitle': flyer.subtitle,
					'click-title': flyer.title,
				}}
				heapEventName="flyers-feature-image-click"
				linkToInternal={flyer.anchorHref}>
				<Image
					alt={flyer.imageAlt}
					layout="fill"
					objectFit="cover"
					objectPosition="top"
					src={flyer.imageSrc}
				/>
			</Clickable>

			<div
				className="absolute bottom-0 left-4 right-4"
				style={{
					background: 'linear-gradient(0.24deg, rgba(0, 0, 0, 0.75) 10%, rgba(62, 62, 62, 0) 99%)',
				}}>
				<div className="w-full p-4 flex flex-col gap-2">
					<Typography className={classNames(flyer.classText)} variant="subhead-md">
						{flyer.title}
					</Typography>

					{flyer.subtitleIsHtml !== true && (
						<Typography
							className={classNames('normal-case pb-3 ', flyer.classText)}
							component="p"
							variant="body">
							{flyer.subtitle}
						</Typography>
					)}

					{flyer.subtitleIsHtml === true && <>{flyer.subtitle}</>}

					<Clickable
						className="bg-white capitalize flex font-bold justify-center px-2 py-1 rounded-full text-gray-600 tracking-wide mr-auto"
						heapEventData={{
							'click-anchor-href': flyer.anchorHref,
							'click-subtitle': flyer.subtitle,
							'click-title': flyer.title,
						}}
						heapEventName="flyers-feature-button-click"
						linkToInternal={flyer.anchorHref}>
						{flyer.buttonText || flyer.anchorText}
						<Icon className="ml-1 self-center" height="12px" name="caretRight" width="12px" />
					</Clickable>
				</div>
			</div>
		</div>
	);
};

FlyerFeature.propTypes = {
	flyer: PropTypes.object,
};

export default FlyerFeature;
